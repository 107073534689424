import React from "react"
import tw from "twin.macro"
import PropTypes from "prop-types"

const BorderedCard = ({ cardData, narcolepsy, ih, addedStyles }) => {
  return (
    <div
      css={[
        narcolepsy && tw`gradient44`,
        ih && tw`large-callout-gradient-hcp-ih`,
        tw`w-full h-full relative z-10 rounded-[45px] py-9 px-[30px] before:(content[''] absolute left-0 right-0 top-0 bottom-0 m-[5px] bg-white rounded-[41px] xl:(rounded-[56px])) md:px-[25px] xl:(px-[30px] rounded-[60px])`,
        addedStyles && addedStyles,
      ]}
    >
      {cardData.image && (
        <img
          css={[
            tw`relative h-[80px] mx-auto mb-6 mt-0 xl:(mb-5 h-[150px])`,
            cardData.addedStyles && cardData.addedStyles,
          ]}
          src={cardData.image}
          alt=""
        />
      )}
      <div tw="relative z-20 text-center">
        {cardData.heading && (
          <h4 tw="uppercase font-semibold leading-[130%] xl:(leading-[140%])">
            {cardData.heading}
          </h4>
        )}
        {cardData.text && (
          <div tw="mt-[15px] leading-[130%] xl:leading-[150%]">
            {cardData.text}
          </div>
        )}
      </div>
    </div>
  )
}

export default BorderedCard

BorderedCard.propTypes = {
  cardData: PropTypes.object.isRequired,
  minHeight: PropTypes.string,
  narcolepsy: PropTypes.bool,
  ih: PropTypes.bool,
}

BorderedCard.defaultProps = {
  cardData: {},
  minHeight: "",
  narcolepsy: false,
  ih: false,
}
