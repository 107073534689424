import React from "react"
import tw from "twin.macro"
import PropTypes from "prop-types"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import Section from "@components/section"
import Container from "@components/container"
import GradientBeforeCallout from "@components/gradientBeforeCallout"
import Anchor from "@components/anchor"
import BorderedCard from "@components/borderedCard"
import References from "@components/references"
import { Heading1, Heading2, purpleButton } from "@styles/ui"

// Assets
import logo from "@images/logos/logo-colourful-outline.svg"
import fax from "@images/icons/icon-fax.svg"
import computer from "@images/icons/icon-computer.svg"
import mail from "@images/icons/icon-mail.svg"
import BlurredBgCallout from "../components/blurredBgCallout"

const XywavAndXyremProgram = ({ location }) => {
  const programUrl = "https://www.xywavxyremrems.com/"
  const transitioningUrl = "/narcolepsy/transitioning-from-xyrem/"
  const dosingNarcUrl = "/narcolepsy/dosing-and-titration/"
  const dosingIhUrl = "/idiopathic-hypersomnia/dosing/"
  const referenceUrl =
    "https://www.accessdata.fda.gov/Scripts/Cder/Rems/Index.cfm?event=IndvRemsDetails.page&REMS=345"

  const gradientCalloutData = {
    dataOne: {
      heading: (
        <>
          <span tw="font-normal lg:(block max-w-[807px])">
            Your{" "}
            <strong>
              patients taking XYREM are already enrolled in the XYWAV and XYREM
              REMS{" "}
            </strong>
            and will not need to be re-enrolled
          </span>
        </>
      ),
      text: (
        <>
          <Anchor link={transitioningUrl} underline addedStyles={tw`text-base`}>
            Learn more about transitioning appropriate XYREM patients to XYWAV
          </Anchor>
          <ul tw="pl-4 mt-[30px] relative">
            <li tw="text-base before:(content['&bull;'] absolute left-0 top-0)">
              If you prescribe XYREM and are already certified in the XYWAV and
              XYREM REMS, you will not <br tw="hidden lg:inline" /> need to{" "}
              <nobr>recertify</nobr>
            </li>
          </ul>
        </>
      ),
    },
    dataTwo: {
      heading: (
        <>
          <span tw="font-normal">
            <strong>Keep the following additional information </strong>
            in mind
          </span>
        </>
      ),
      text: (
        <>
          <ul tw="mt-[30px]">
            <li tw="text-base relative pl-4 before:(content['&bull;'] absolute left-0 top-0)">
              All required fields must be completed clearly and accurately for
              timely fulfillment of the patient’s prescription. Required fields
              are indicated by an asterisk (*)
            </li>
            <li tw="text-base relative pl-4 pt-4 before:(content['&bull;'] absolute left-0 top-4)">
              Personal prescription blanks will not be accepted
            </li>
            <li tw="text-base relative pl-4 pt-4 before:(content['&bull;'] absolute left-0 top-4)">
              The patient’s first shipment of XYWAV will be limited to a{" "}
              <nobr>1-month</nobr> (30-day) supply, and refill shipments cannot
              exceed a 3-month (90-day) supply<sup>2</sup>
            </li>
            <li tw="text-base relative pl-4 pt-4 before:(content['&bull;'] absolute left-0 top-4)">
              Each Prescription Form is valid for 6 months from the date written
              (initial fill plus 5 refills per prescription)<sup>2</sup>
            </li>
          </ul>
        </>
      ),
    },
  }

  const cardDataOne = {
    image: computer,
    heading: <span tw="normal-case">Online</span>,
    text: (
      <p>
        <Anchor link={programUrl} isExternal newTab underline>
          XYWAVXYREMREMS.com
        </Anchor>
      </p>
    ),
    addedStyles: tw`h-[53px] md:(mt-[29px]) lg:(h-[70px] mb-[22px] mt-[22px])`,
  }

  const cardDataTwo = {
    image: fax,
    heading: <span tw="normal-case">Fax</span>,
    text: (
      <p>
        <a href="tel:1-866-470-1744" tw="underline hover:no-underline">
          1-866-470-1744
        </a>
        <br />
        (toll-free)
      </p>
    ),
    addedStyles: tw`h-[60px] md:(h-[82px]) lg:(mb-[22px])`,
  }

  const cardDataThree = {
    image: mail,
    heading: <span tw="normal-case">Mail</span>,
    text: (
      <p>
        XYWAV and XYREM REMS
        <br />
        PO Box 66589
        <br />
        St. Louis, MO 63166-6589
      </p>
    ),
    addedStyles: tw`h-[53px] md:(mt-[29px]) lg:(h-[61px] mb-[22px] mt-[22px])`,
  }

  const referenceData = [
    <>
      XYWAV<sup>&reg;</sup> (calcium, magnesium, potassium, and sodium
      oxybates). Prescribing Information. Palo Alto, CA: Jazz Pharmaceuticals,
      Inc.
    </>,
    <>
      XYWAV and XYREM REMS Document. NDA 21196; NDA 212690. XYWAV
      <sup>&reg;</sup> (calcium, magnesium, potassium, and sodium oxybates) and
      XYREM<sup>&reg;</sup> (sodium oxybate). Palo&nbsp;Alto, CA: Jazz
      Pharmaceuticals, Inc. Approved 02/2015.
      <Anchor
        link={referenceUrl}
        isExternal
        newTab
        underline
        addedStyles={tw`break-words`}
      >
        {" "}
        https://www.accessdata.fda.gov/Scripts/Cder/Rems/Index.cfm?event=IndvRemsDetails.page&REMS=345
      </Anchor>
      . <nobr>Accessed September 15, 2020.</nobr>
    </>,
    <>
      XYREM<sup>&reg;</sup> (sodium oxybate). Prescribing Information. Palo
      Alto, CA: Jazz Pharmaceuticals, Inc.
    </>,
  ]

  return (
    <Layout
      location={location}
      copyright={
        <>
          © 2024 Jazz Pharmaceuticals plc or its subsidiaries{" "}
          <br tw="block md:hidden" />
          US-XYW-2200207 Rev0824
        </>
      }
    >
      <Seo
        title="How to Enroll Your Patient in XYWAV® REMS Programs | XYWAV HCP"
        description="Find out how to enroll your XYWAV® (calcium, magnesium, potassium, and 
          sodium oxybates) patient in XYWAV and XYREM REMS. See full Prescribing 
          Information & BOXED Warning about serious side effects, CNS depression, 
          and misuse and abuse."
        location={location}
      />

      <Section
        addedStyles={tw`relative overflow-hidden cost-and-coverage-hero-gradient pt-[40px] pb-[117px] rounded-bl-[100px] lg:(pt-[58px] pb-[105px] rounded-bl-[150px])`}
      >
        <img
          src={logo}
          tw="absolute right-[-20px] bottom-[-85px] w-[250px] h-[220px] md:(w-[570px] h-[600px] right-[-65px] top-[25px]) xl:(w-[770px])"
          alt=""
        />
        <Container>
          <p tw="mb-2.5 uppercase font-semibold text-xs text-merlin tracking-[0.6px] lg:(text-base)">
            The Xywav &amp; Xyrem rems
          </p>
          <h1
            css={[
              Heading1,
              tw`w-full max-w-[500px] md:max-w-[444px] xl:(w-[unset] max-w-[700px] text-[45px])`,
            ]}
          >
            <strong>Learn how to get your patients</strong> started on XYWAV
          </h1>
          <p tw="pt-[30px] pr-[17px] text-base md:max-w-[444px] xl:(max-w-[700px] text-xl pr-0)">
            All prescribers must be certified in the XYWAV and XYREM REMS to
            prescribe XYWAV. Those currently certified in the XYREM REMS are not
            required <br tw="lg:hidden" /> to <nobr>recertify.</nobr>
          </p>
        </Container>
      </Section>

      <Section addedStyles={tw`lg:(pt-[65px] pb-0)`}>
        <Container>
          <h2 css={Heading2}>
            Start by ensuring your patients are enrolled{" "}
            <span tw="font-normal">in the XYWAV and XYREM REMS</span>
          </h2>
          <p tw="pt-5">
            XYWAV will be dispensed and shipped only to patients who are
            enrolled in the XYWAV and XYREM REMS with documentation of safe use.
            <sup>
              <nobr>1-3</nobr>
            </sup>
          </p>
          <ul tw="pl-4 pt-4 xl:pt-3">
            <li tw="text-base relative before:(content['&bull;'] absolute left-[-15px] top-0)">
              To learn more about certifying your patients and getting started,
              visit{" "}
              <Anchor link={programUrl} isExternal newTab underline>
                XYWAVXYREMREMS.com
              </Anchor>
              .
            </li>
          </ul>
        </Container>
      </Section>

      <Section addedStyles={tw`pt-[5px] pb-0 lg:py-[35px]`}>
        <Container>
          <GradientBeforeCallout
            heading={gradientCalloutData.dataOne.heading}
            text={gradientCalloutData.dataOne.text}
            indication="agnostic"
            bgColour="white"
            addedStyles={tw`pt-0 lg:(ml-[65px])`}
          />
        </Container>
      </Section>

      <Section addedStyles={tw`bg-alabaster-2 lg:(pt-[65px] pb-10)`}>
        <Container addedStyles={tw`lg:pl-[60px]`}>
          <p tw="text-2xl lg:(text-4xl ml-[-60px])">
            Follow these steps for your{" "}
            <strong>patients who are not currently taking XYREM</strong>
          </p>
          <p tw="mt-12 ml-[33px] text-[20px] relative before:(content-['1'] text-[28px] gradientAngled bg-clip-text text-transparent font-medium w-[18px] absolute top-[-5px] left-[-29px]) lg:(text-[26px] before:(content-['1'] text-[45px] gradientAngled bg-clip-text text-transparent font-medium w-[18px] absolute top-[-8px] left-[-45px]))">
            Complete the XYWAV Prescription Form and have the patient sign the
            Patient Enrollment Form<sup>2</sup>
          </p>
          <p tw="mt-11 ml-[33px] font-semibold">
            By signing the XYWAV Prescription Form, the prescriber…
          </p>
          <p tw="mt-5 ml-[33px] font-semibold uppercase">
            Acknowledges that he or she<sup tw="font-normal">2</sup>:
          </p>
          <ul tw="pl-4 mt-4 ml-[33px]">
            <li tw="text-base relative before:(content['&bull;'] absolute left-[-15px] top-0)">
              Has read and understands the XYWAV full Prescribing Information
              (PI) and the XYWAV and XYREM REMS Prescriber Brochure
            </li>
            <li tw="text-base relative mt-4 before:(content['&bull;'] absolute left-[-15px] top-0)">
              Understands the approved indications, risks, and safe use
              conditions of XYWAV
            </li>
          </ul>
          <p tw="mt-5 ml-[33px] font-semibold uppercase">
            Attests that<sup tw="font-normal">2</sup>:
          </p>
          <ul tw="pl-4 mt-4 ml-[33px]">
            <li tw="text-base relative before:(content['&bull;'] absolute left-[-15px] top-0)">
              XYWAV is medically appropriate for the patient
            </li>
            <li tw="text-base relative mt-4 before:(content['&bull;'] absolute left-[-15px] top-0)">
              He or she has screened the patient for certain medical conditions
              and certain concomitant medications. For more information, visit{" "}
              <Anchor link={programUrl} isExternal newTab underline>
                XYWAVXYREMREMS.com
              </Anchor>
            </li>
            <li tw="text-base relative mt-4 before:(content['&bull;'] absolute left-[-15px] top-0)">
              He or she has counseled the patient on the serious risks and safe
              use conditions of XYWAV and the preparation and dosing
              instructions for XYWAV. For more information, visit{" "}
              <Anchor link={programUrl} isExternal newTab underline>
                XYWAVXYREMREMS.com
              </Anchor>
            </li>
            <li tw="text-base relative mt-4 before:(content['&bull;'] absolute left-[-15px] top-0)">
              The online REMS Patient Enrollment Form can be found{" "}
              <Anchor link={programUrl} isExternal newTab underline>
                here
              </Anchor>
              .
            </li>
          </ul>
          <p tw="mt-12 ml-[33px] text-[20px] relative before:(content-['2'] text-[28px] gradientAngled bg-clip-text text-transparent font-medium w-[18px] absolute top-[-5px] left-[-29px]) lg:(text-[26px] before:(content-['2'] text-[45px] gradientAngled bg-clip-text text-transparent font-medium w-[25px] absolute top-[-8px] left-[-50px]))">
            Submit the completed and signed front page of the XYWAV Prescription
            Form to the Certified Pharmacy by either:
          </p>
          <div tw="flex flex-col mt-[35px] gap-[30px] lg:(flex-row justify-start mt-[30px])">
            <BorderedCard
              cardData={cardDataOne}
              addedStyles={tw`md:(h-[281px] py-10 flex flex-col justify-start) lg:(w-[470px] h-auto pt-[66px] before:m-0)`}
            />
            <BorderedCard
              cardData={cardDataTwo}
              addedStyles={tw`md:(h-[281px] py-10 flex flex-col justify-start) lg:(w-[470px] h-auto pt-[66px] before:m-0)`}
            />
            <BorderedCard
              cardData={cardDataThree}
              addedStyles={tw`md:(h-[281px] py-10 flex flex-col justify-start) lg:(w-[470px] h-auto pt-[66px] pb-[56px] before:m-0)`}
            />
          </div>
          <GradientBeforeCallout
            heading={gradientCalloutData.dataTwo.heading}
            text={gradientCalloutData.dataTwo.text}
            indication="agnostic"
            bgColour="white"
            addedStyles={tw`bg-alabaster-2 pt-10 pb-4 lg:(pt-[65px] max-w-[875px])`}
          />
        </Container>
      </Section>

      <Section addedStyles={tw`lg:(pt-[65px] pb-[90px])`}>
        <Container>
          <p tw="text-2xl lg:text-[26px]">
            <strong>To refill or renew </strong>a XYWAV prescription, you can:
          </p>
          <ul tw="mt-[30px]">
            <li tw="relative pl-4 before:(content['&bull;'] absolute left-0 top-0)">
              Call the Certified Pharmacy at{" "}
              <a href="tel:1-866-997-3688" tw="underline hover:no-underline">
                <nobr>1-866-997-3688</nobr>
              </a>
            </li>
            <li tw="relative pl-4 pt-4 before:(content['&bull;'] absolute left-0 top-4)">
              Fax the completed XYWAV Prescription Form to 1-866-470-1744
            </li>
            <li tw="relative pl-4 pt-4 before:(content['&bull;'] absolute left-0 top-4)">
              Submit the refill or renewal electronically
            </li>
            <li tw="relative pl-4 pt-4 before:(content['&bull;'] absolute left-0 top-4)">
              Mail the completed XYWAV Prescription Form to XYWAV and XYREM
              REMS, PO Box 66589, St. Louis, MO 63166-6589
            </li>
          </ul>
        </Container>
      </Section>
      <Container addedStyles={tw`w-full`}>
        <BlurredBgCallout agnostic>
          <h2
            css={[
              tw`mb-5 mt-5 font-semibold text-center text-4xl text-merlin lg:(mt-0 mb-[16px] text-[26px])`,
            ]}
          >
            The XYWAV Prescription Form is used for:
          </h2>

          <ul tw="relative text-merlin flex flex-col items-start left-[10%] lg:left-[24%]">
            <li tw="text-base text-center list-disc leading-[22.3px] marker:(text-[12px]) lg:(text-3xl leading-[28.6px])">
              Initial prescriptions
            </li>
            <li tw="text-base list-disc leading-[22.3px] marker:(text-[12px]) lg:(text-3xl leading-[28.6px])">
              Patients who are reinitiating treatment after a{" "}
              <br tw="hidden lg:inline" /> lapse in therapy of 6&nbsp;months or
              longer
            </li>
            <li tw="text-base list-disc leading-[22.3px] marker:(text-[12px]) lg:(text-3xl leading-[28.6px])">
              Refills and renewals
            </li>
          </ul>
        </BlurredBgCallout>
      </Container>

      <Section addedStyles={tw`pt-10 pb-2.5 lg:(pt-[60px] pb-[30px])`}>
        <Container addedStyles={tw`flex flex-col lg:flex-row`}>
          <Anchor
            link={dosingNarcUrl}
            addedStyles={[
              purpleButton,
              tw`w-full mx-auto py-2.5 px-8 lg:(w-1/2 mr-[22px] py-[15px])`,
            ]}
          >
            Dosing &amp; Titration <br tw="inline md:hidden" /> (Narcolepsy)
          </Anchor>
          <Anchor
            link={dosingIhUrl}
            addedStyles={[
              purpleButton,
              tw`w-full mt-7 mx-auto py-2.5 px-8 lg:(mt-0 ml-[22px] w-1/2 px-0 py-[15px])`,
            ]}
          >
            Dosing &amp; Titration <br tw="inline md:hidden" /> (Idiopathic
            Hypersomnia)
          </Anchor>
        </Container>
      </Section>

      <References referenceData={referenceData} paddingPrimary />
    </Layout>
  )
}

export default XywavAndXyremProgram

XywavAndXyremProgram.propTypes = {
  location: PropTypes.object.isRequired,
}
